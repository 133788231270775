import MartEyeLogo from "@/components/logos/standard";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { firestore } from "firebase-admin";
import { getFirestore } from "firebase-admin/firestore";
import { NextPageContext } from "next";
import Head from "next/head";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { getSessionNull } from "../auth/serverside-getSession";
import { initialiseFirebaseAdmin } from "../initFirebaseAdmin";
import { MarketInfo } from "../pages/_app";

let css = `
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fade-in {
    animation-delay: 1s;
    animation: fadeIn ease-in 1s;
    animation-fill-mode: forwards;
  }
`;

export default function Home(props: { isLoggedIn: boolean }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  let canvasRef = useRef(null as null | HTMLCanvasElement);

  useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const cattleArray: Cattle[] = [];

    for (let i = 0; i < 50; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const size = Math.random() * 10 + 5;
      const speed = Math.random() * 1 + 0.2;
      cattleArray.push(createCattle(canvas, ctx, x, y, size, speed));
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (const cattle of cattleArray) {
        cattle.draw();
        cattle.update();
      }

      requestAnimationFrame(animate);
    }

    animate();
  }, []);

  return (
    <div>
      <Head>
        <title>MartEye Studio</title>
        <style>{css}</style>
      </Head>

      <main>
        <div className="bg-navbg absolute inset-0 -z-10">
          <canvas ref={canvasRef} className="absolute inset-0 fade-in"></canvas>
          <div
            className="absolute inset-0 "
            style={{
              backdropFilter: "blur(3px)",
              WebkitBackdropFilter: "blur(3px)",
            }}
          ></div>
        </div>
        <div className="fade-in">
          <header className="absolute inset-x-0 top-0 z-50">
            <nav
              className="flex items-center justify-between p-6 lg:px-8"
              aria-label="Global"
            >
              <div className="flex lg:flex-1">
                {/* <Link href="/" className="-m-1.5 p-1.5">
                  <MartEyeLogo className="h-8 w-auto" />
                </Link> */}
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden lg:flex lg:gap-x-12"></div>
              {props.isLoggedIn === false ? (
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                  <Link
                    href="/login"
                    className="text-sm font-semibold leading-6 text-martEye-200"
                  >
                    Log in <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              ) : (
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                  <Link
                    href="/logout"
                    className="text-sm font-semibold leading-6 text-martEye-200"
                  >
                    Log out
                  </Link>
                </div>
              )}
            </nav>
            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <Link href="/" className="-m-1.5 p-1.5">
                    <MartEyeLogo className="h-8 w-auto" />
                  </Link>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="space-y-2 py-6"></div>
                    {props.isLoggedIn === false ? (
                      <div className="py-6">
                        <Link
                          href="/login"
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Log in
                        </Link>
                      </div>
                    ) : (
                      <div className="py-6">
                        <Link
                          href="/logout"
                          className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          Log out
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>

          <div className="relative isolate px-6 pt-0 lg:px-8">
            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
              <div className="text-center">
                <div className="flex justify-center mb-9">
                  <MartEyeLogo className="h-24 w-auto " colour={"#DFF3E0"} />
                </div>

                <div className="mt-14 flex items-center justify-center gap-x-6">
                  <a
                    href="https://airtable.com/shr0ifPqZlQEeArda"
                    className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Join the Waitlist <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

interface Cattle {
  x: number;
  y: number;
  size: number;
  speed: number;
  direction: number;
  timer: number;
  maxTimer: number;
  draw: () => void;
  update: () => void;
}

const createCattle = (
  canvas: HTMLCanvasElement,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  speed: number
): Cattle => {
  let possibleColours = [
    "#162F27",
    "#294F43",
    "#497D5C",
    "#DFF3E0",
    "#A9DA75",
    "#0E1513",
    "#DFF3E0",
    "#283A35",
    "#3C504A",
  ];

  let colour =
    possibleColours[Math.floor(Math.random() * possibleColours.length)];

  return {
    x,
    y,
    size,
    speed,
    direction: Math.random() * Math.PI * 2,
    timer: 0,
    maxTimer: Math.floor(Math.random() * 100) + 50,
    draw() {
      // Body
      ctx.fillStyle = colour;
      ctx.fillRect(this.x, this.y, this.size * 2, this.size);

      // Head
      ctx.beginPath();
      ctx.arc(
        this.x + this.size * 2,
        this.y + this.size / 2,
        this.size / 2,
        0,
        Math.PI * 2
      );
      ctx.fillStyle = colour;
      ctx.fill();

      // Legs
      ctx.fillRect(
        this.x + this.size / 2,
        this.y + this.size,
        this.size / 4,
        this.size / 2
      );
      ctx.fillRect(
        this.x + this.size * 1.5,
        this.y + this.size,
        this.size / 4,
        this.size / 2
      );
    },
    update() {
      this.timer++;
      if (this.timer > this.maxTimer) {
        this.direction = Math.random() * Math.PI * 2;
        this.maxTimer = Math.floor(Math.random() * 100) + 50;
        this.timer = 0;
      }

      this.x += Math.cos(this.direction) * this.speed;
      this.y += Math.sin(this.direction) * this.speed;

      if (this.x < -this.size * 2) this.x = canvas.width;
      if (this.x > canvas.width + this.size * 2) this.x = -this.size * 2;
      if (this.y < -this.size) this.y = canvas.height;
      if (this.y > canvas.height + this.size) this.y = -this.size;
    },
  };
};

// server props get headers
export async function getServerSideProps(context: NextPageContext) {
  // get and verify the session cookie
  let { session, cookieExpired } = await getSessionNull(context);
  if (!session) {
    if (cookieExpired) {
      return {
        redirect: {
          permanent: false,
          destination: "/login",
        },
      };
    }

    return {
      props: {
        isLoggedIn: false,
      },
    };
  }

  let mkts = session.mkts as { [marketId: string]: string };
  let admin = initialiseFirebaseAdmin();

  let db = getFirestore(admin);

  if (mkts) {
    // Get the market profile for all the markets I'm a member of
    let refs = Object.keys(mkts).map((marketId) => {
      return db.doc(`/markets/${marketId}`);
    });

    let snaps = await firestore().getAll(...refs);

    let markets: MarketInfo[] = snaps
      .filter((snap) => snap.exists)
      .map((snap) => snap.data() as firestore.DocumentData)
      .map((data) => ({
        id: data.id,
        name: data.name,
        countryCode: data.countryCode,
        logo: data.logo,
        primaryColour: data.primaryColour,
        secondaryColour: data.secondaryColour,
      }));

    // if there are markets, check for the last used in the market cookie or redirect to the first one
    if (markets.length > 0) {
      let cookieMarketId = context.req?.headers.cookie
        ?.split("market=")[1]
        ?.split(";")[0];

      // Ensure the cookie market is one of the markets the user is a member of
      let marketId = markets[0].id;
      if (cookieMarketId && mkts[cookieMarketId] !== undefined) {
        marketId = cookieMarketId;
      }

      return {
        redirect: {
          permanent: false,
          destination: `/${marketId}`,
        },
        props: {},
      };
    }
  }

  // otherwise they see the setup page
  return {
    redirect: {
      permanent: false,
      destination: "/market-list",
    },
    props: {
      isLoggedIn: true,
    },
  };
}
